<template>
  <el-dialog
    :visible.sync="currentShowFlag"
    append-to-body
    fullscreen
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @open="handleDialogOpen"
    @closed="handleDialogClosed"
  >
    <div
      slot="title"
      class="detail-dialog__header"
    >
      {{ $t('system_user.detailDialog') }}
    </div>
    <div>
      <simple-form
        ref="form"
        v-model="formModel"
        label-width="80px"
        :form-field="formField"
        :grid="{xs: 24, sm: 12, md: 8}"
        :view-flag="viewFlag"
      />
    </div>
    <div slot="footer">
      <el-button
        @click="handleDialogClose"
      >
        {{ viewFlag ? $t('operation.close') : $t('operation.cancel') }}
      </el-button>
      <el-button
        v-if="!viewFlag"
        type="primary"
        @click="handleDataSubmit"
      >
        {{ $t('operation.submit') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
const BASEURL = {
  update: '/system/user/update',
  get: '/system/user/get',
  save: '/system/user/save',

  office: '/system/office/list',
  role: '/system/role/list'
}
export default {
  name: 'SystemUserDetail',
  props: {
    showFlag: { type: Boolean, default: false },
    viewFlag: { type: Boolean },
    detailData: { type: Object }
  },
  data () {
    return {
      formModel: { company: {}, status: 'Y', roleList: [] },

      companyList: [],
      companyLoadingFlag: false,

      sysRoleList: [],
      regionRule: {}
    }
  },
  inject: ['handleDataSearch'],
  computed: {
    currentShowFlag: {
      get () {
        return this.showFlag
      },
      set (val) {
        this.$emit('update:showFlag', val)
      }
    },

    status () {
      return this.$getDictList('data_status')
    },

    formField () {
      return [
        {
          prop: 'company',
          label: this.$t('system_user.companyName'),
          type: 'SearchableInput',
          component: {
            remoteMethod: this.getCompanyList,
            loading: this.companyLoadingFlag,
            optionList: this.companyList,
            valueKey: 'id',
            clearable: true
          },
          event: {
            focus: () => {
              this.getCompanyList()
            },
            change: obj => this.handleCompanyClick(obj)
          },
          rules: {
            type: 'object',
            trigger: 'change',
            message: this.$t('validate.notSelect'),
            required: true,
            fields: {
              id: {
                type: 'string',
                message: this.$t('validate.notSelect'),
                required: true
              }
            }
          }
        },
        // {
        //   prop: 'dept',
        //   label: this.$t('system_user.deptName'),
        //   type: 'SearchableInput',
        //   component: {
        //     clearable: true,
        //     remoteMethod: this.getDeptList,
        //     loading: this.deptLoadingFlag,
        //     optionList: this.deptList,
        //     valueKey: 'id'
        //   },
        //   event: {
        //     focus: () => {
        //       this.getDeptList()
        //     },
        //     clear: () => {
        //       delete this.formModel.dept
        //     }
        //   }
        // },
        {
          prop: 'loginName',
          label: this.$t('system_user.loginName'),
          type: 'Input',
          component: { clearable: true },
          rules: {
            trigger: 'blur',
            message: this.$t('validate.notBlank'),
            required: true
          }
        },
        {
          prop: 'newPassword',
          label: this.$t('system_user.password'),
          type: 'Input',
          component: { placeholder: this.$t('tip.defaultPassword'), clearable: true, disabled: true },
          hidden: this.viewFlag
        },
        {
          prop: 'name',
          label: this.$t('system_user.name'),
          type: 'Input',
          component: { clearable: true },
          rules: {
            trigger: 'blur',
            message: this.$t('validate.notBlank'),
            required: true
          }
        },
        {
          prop: 'roleList',
          label: this.$t('system_user.roleList'),
          type: 'Select',
          component: {
            optionList: this.sysRoleList,
            multiple: true,
            valueKey: 'id',
            clearable: true
          },
          rules: {
            type: 'array',
            trigger: 'change',
            message: this.$t('validate.notSelect'),
            required: true
          },
          event: {
            change: val => {
              this.handleRoleListChange(val)
            }
          }
        },
        // {
        //   prop: 'phone',
        //   label: this.$t('system_user.phone'),
        //   type: 'Input',
        //   component: { clearable: true },
        //   rules: { trigger: 'blur', message: this.$t('validate.notBlank'), required: true }
        // },
        {
          prop: 'status',
          label: this.$t('system_user.status'),
          type: 'Select',
          component: { optionList: this.status, clearable: true },
          rules: {
            trigger: 'blur',
            message: this.$t('validate.notBlank'),
            required: true
          }
        },
        {
          prop: 'region',
          label: this.$t('system_office.region'),
          type: 'Select',
          component: { optionList: this.$getDictList('sys_office_region'), clearable: true },
          rules: this.regionRule
        },
        {
          prop: 'email',
          label: this.$t('system_office.email'),
          type: 'Input',
          component: { clearable: true },
          rules: [
            { trigger: 'blur', message: this.$t('validate.notValid'), type: 'email' },
            { trigger: 'blur', message: this.$t('validate.notBlank'), required: true }
          ]
        },
        {
          prop: 'remarks',
          label: this.$t('system_user.remarks'),
          type: 'Input',
          component: { type: 'textarea', clearable: true },
          col: { xs: 24, sm: 24, md: 24 }
        }
      ]
    }
  },
  methods: {
    handleDialogClose () {
      this.currentShowFlag = false
    },

    handleRoleListChange (items) {
      const result = items.find(ele => {
        return ele.id === 'partner' || ele.id === 'factoryA'
      })
      if (result) {
        this.formModel.region = null
        this.regionRule = null
      } else {
        this.regionRule = { trigger: 'blur', message: this.$t('validate.notSelect'), required: true }
      }
    },

    getCompanyList (name) {
      this.companyLoadingFlag = true
      const filter = []
      if (name) { filter.push({ left: 'name', operate: 'like', right: name }) }
      this.$axios
        .post(BASEURL.office, { pageSize: 10, filter: filter })
        .then(resp => {
          const respData = resp.data
          this.companyList = respData.list.map(item => {
            return { key: item.id, value: item, label: item.name }
          })
        })
        .finally(() => {
          this.companyLoadingFlag = false
        })
    },

    getSysRoleList () {
      this.$axios.post(BASEURL.role, {}).then(resp => {
        const respData = resp.data
        this.sysRoleList = respData.list.map(item => {
          return { key: item.id, value: item, label: item.name }
        })
      })
    },

    handleCompanyClick (obj) {
      if (!this.formModel.region) this.$set(this.formModel, 'region', obj.region)
      if (!this.formModel.email) this.$set(this.formModel, 'email', obj.email)
    },

    handleDataSubmit () {
      this.$refs.form.$refs.simpleForm.validate(status => {
        if (status) {
          const loadingFlag = this.$loading({
            target: this.$el.querySelector('.el-dialog')
          })

          const submitModel = this.$_.cloneDeep(this.formModel)
          if (!this.detailData.id && !submitModel.newPassword) {
            submitModel.newPassword = '123456'
          }

          this.$axios
            .post(
              this.detailData.id ? BASEURL.update : BASEURL.save,
              submitModel
            )
            .then(resp => {
              this.currentShowFlag = false
              this.$message({
                type: 'success',
                message: this.$t('tip.saveSuccess')
              })
              this.handleDataSearch()
            })
            .finally(() => {
              loadingFlag.close()
            })
        }
      })
    },

    handleDialogOpen () {
      if (!this.detailData.id) {
        this.getSysRoleList()
        return
      }
      const loadingFlag = this.$loading({
        target: this.$el.querySelector('.el-dialog')
      })

      this.$axios
        .post(BASEURL.get, { id: this.detailData.id })
        .then(resp => {
          const respData = resp.data
          this.initialSelectOptionList(respData.company)
          // delete respData.password
          this.formModel = respData
        })
        .finally(() => {
          loadingFlag.close()
        })
    },

    initialSelectOptionList (company) {
      this.companyList = [
        { key: company.id, value: company, label: company.name }
      ]
      this.getSysRoleList()
    },

    handleDialogClosed () {
      this.$refs.form.resetFields()
      this.formModel = { company: {}, status: 'Y', roleList: [] }
    }
  }
}
</script>
